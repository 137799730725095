import type { Ref, ShallowRef } from "vue";

export interface IUseAsyncReturns<T> {
  data: ShallowRef<T | null>;
  error: Ref<string | null>;
  loading: Ref<boolean>;
  run: (...args: any[]) => Promise<void>;
}

export interface IUseAsyncOptions<T> {
  onSuccess?: (data: T) => void;
  onError?: (err: Error) => void;
}

export default function useAsync<T>(
  promise: Promise<T> | ((...args: any[]) => Promise<T>),
  options?: IUseAsyncOptions<T>,
): IUseAsyncReturns<T> {
  const data = shallowRef<T | null>(null);
  const error = ref<string | null>(null);

  const loading = ref(false);
  const run = async (...args: any[]) => {
    loading.value = true;
    error.value = null;

    try {
      const _promise = typeof promise === "function" ? promise(...args) : promise;
      const response = await _promise;

      data.value = response;

      if (options?.onSuccess) {
        error.value = null;
        options.onSuccess(response);
      }
    } catch (err) {
      error.value = (err as Error).message;
      if (options?.onError)
        options.onError(err as Error);
    } finally {
      loading.value = false;
    }
  };

  return { data, error, loading, run };
}
